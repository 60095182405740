.login-form-cover{
    background-color: var(--secondary-color);
    height: 100vh;
}

.login-form{
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    width: 100%;
    max-width: 1000px;
}
.login-form-left-side{
    position: relative;
    width: calc(100% - 450px);
}
.login-form-left-side div:last-child{
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.login-form-right-side{
    width: 450px;
    background: var(--white-color);
}

.ant-input-suffix .anticon svg{
    color: var(--black-color) !important;
}

@media only screen and (max-width: 976px) {
    .login-form{
        width: 93%;
    }
    /* .login-form img{max-width: 400px;} */
}

