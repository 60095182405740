.notifications .ant-list-item>div:last-of-type{
    display: none;
}
.nofitication-tabs .ant-badge-count{
    transform: translate(80%, -80%) !important;
}

.btm-btn{
    position: absolute;
    bottom: 10px;
    left: 21px;
    width: 90% !important;
}
.user-profile-drawer .ant-divider-horizontal {
    margin: 19px 0 !important
}