.ant-upload-wrapper .ant-upload-list{
    display: flex !important;
    justify-content: flex-start !important;
}
.ant-upload-select{
  width: 100% !important;
}

.searchinput input{
    height: 45px !important;
    border-color: var(--inactive-color) !important;
    background-color: transparent !important;
    color: var(--black-color) !important;
    box-shadow: none !important;
}

/* .searchinput input::placeholder{
    color: rgba(255, 255, 255, .5) !important;
} */

.searchinput textarea{
    border-color: var(--inactive-color) !important;
    background-color: transparent !important;
    /* color: #fff !important; */
    box-shadow: none !important;
}

/* .searchinput textarea::placeholder{
    color:  rgba(255, 255, 255, .5) !important;
} */

.searchinput .ant-input-affix-wrapper {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.select .ant-select-selector {
    background-color: transparent !important; 
    color: var(--black-color) !important;
    border-color: var(--inactive-color) !important; 
  }

  /* .ant-select-dropdown{
    background: var(--secondary-color) !important;
  } */
  
  .ant-select-dropdown .ant-select-item{
    color: var(--black-color) !important;
    font-size: 13px !important;
  }

  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
  }

  .select .ant-select-arrow {
    color: var(--black-color) !important; 
  }
  
  .select .ant-select-selection-placeholder {
    color: var(--inactive-color) !important; 
    font-size: 13px;
  }
  
  .select .ant-select-selection-item {
    color: var(--black-color) !important; 
    font-size: 13px;
  }
  
  .label {
    color: var(--black-color) !important; 
  }

  /* .select .ant-select-single.ant-select-lg{
    height: 37px !important;
  } */

  
  
  
  
  .ant-upload, 
  .upload-dragger  {
    color: var(--black-color); 
  }
  
  .upload-d .ant-upload-drag-container {
    border: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


.custom-input .ant-input::placeholder,
.custom-input .ant-input-password input::placeholder,
.custom-input .ant-input-textarea::placeholder {
  /* color: rgba(255, 255, 255, 0.6);  */
}

.transparent-input {
    background-color: transparent !important;
    border-color: var(--inactive-color) !important;
    color: var(--black-color) !important;
    height: 40px !important;
  }


  .transparent-input .ant-picker-input input::placeholder {
    color: var(--black-color) !important;
  }
  
  .transparent-input .ant-picker-suffix {
    color: var(--black-color) !important;
  }
  
  .transparent-input .ant-picker-input input {
    color: var(--black-color) !important;
  }

  .transparent-input .ant-picker-input {
    background-color: transparent !important;
  }

  .transparent-input.ant-picker-focused {
    background-color: transparent !important;
  }

.searchinput .ant-input-outlined{
  border-color: var(--secondary-color) !important;
  border-radius: 12px;
} 

/* .ant-input-group-wrapper-outlined.ant-input-group-wrapper-status-error .ant-input-group-addon {
  border-color: var(--inactive-color) !important;
  color: var(--black-color) !important;
} */

.ant-form-item .ant-form-item-explain-error {
  /* color: var(--black-color) !important; */
  font-size: 11px;
  font-weight: 300 !important;
  padding-top: 3px;
}

.date-input .ant-picker{
  height: 40px !important;
  border-color: var(--inactive-color) !important;
}

.date-input .ant-picker-large .ant-picker-input>input{
  font-size: 13px !important;
}